export const ru = {
    "n1": "титульная страница",
    "n2": "Заказ",
    "n3": "торговля",
    "n4": "команда",
    "n5": "мой",
    "t1": "ошибка",
    "t2": "Репликация",
    "t3": "Пожалуйста, заполните полную информацию",
    "t4": "Загрузка ...",
    "t5": "Успешно приземлился",
    "t6": "Оценка должна быть больше 3, чтобы представить",
    "t7": "Отправлено успешно",
    "t8": "Недостаточный баланс, неспособный получить",
    "t9": "Отправка не удалась",
    "t10": "Сначала свяжите адрес, и страница личного центра собирается прыгнуть",
    "t11": "Для привязки, пожалуйста, свяжитесь с обслуживанием клиентов, чтобы изменить",
    "t12": "Успешно модифицировано",
    "t13": "не редактировать",
    "t14": "Пожалуйста, свяжитесь с обслуживанием клиентов, чтобы изменить пароль",
    "t15": "Ошибка формата пароля",
    "t16": "Успешное уход",
    "t17": "Большая часть вывода",
    "t18": "Минимальные деньги",
    "t19": "Два пароли непоследовательны",
    "t20": "намекать",
    "t21": "Не в состоянии временно прыгать",
    "t22": "Ошибка формата пароля",
    "t23": "Вы успешно зарегистрировались",
    "t24": "Пожалуйста, введите код области",
    "t25": "Больше не надо",
    "l1": "язык",
    "l2": "Пожалуйста, введите имя пользователя",
    "l3": "Пожалуйста, введите пароль",
    "l4": "помнить",
    "l5": "Авторизоваться",
    "l6": "регистр",
    "l7": "Нет аккаунта",
    "l8": "Номер телефона",
    "l9": "Прозвище",
    "l10": "пароль",
    "l11": "Подтвердите пароль",
    "l12": "Код приглашения",
    "l13": "регистр",
    "l14": "Существующий аккаунт",
    "l15": "Авторизоваться",
    "l16": "Пожалуйста, введите 6-16 цифровой пароль",
    "bz1": "помощь",
    "bz2": "Общая проблема",
    "bz3": "Торговые правила",
    "bz4": "Как снять деньги",
    "bz5": "Как перезарядиться",
    "bz6": "1. Что такое робот Mazon Review?",
    "bz7": "Amazon Review Robot - маркетинговая компания, которая помогает Amazon Review Robot Online Store Merchants со всего мира получить больше продаж заказа и увеличить данные просмотра в интернет -магазинах Amazon Review Robot. Мы стремимся создать трехпартийную модель маркетинга прибыли между Amazon Review Robot и торговцами и потребителями. Объединяя новейшую технологию блокчейна P2P, быстро подключают потребителей и продавцов через USDT (TRC20, ERC20). Зарегистрированные пользователи могут получить комиссию за заказы, в то время как торговцы могут увеличить данные о продажах магазина. Это последняя модель прибыли в модели интернет -блокчейна!",
    "bz8": "2. Как работает Mazon Review Robot?",
    "bz9": "Благодаря ежедневной обратной связи Amazon Review Robot, продукты, которые необходимо улучшить в Amazon Review Robot Sales, должны быть улучшены. Пользователи платят сумму заказа через блокчейн USDT и получают ежедневные комиссионные.",
    "bz10": "3. Почему существует разница в цене одной и той же валюты в двух транзакциях?",
    "bz11": "Ценовые различия вызваны всеми факторами, которые не способствуют свободе валюты, включая скорость передачи валюты, условия сети, ограничения доступа валюты, люди в разных регионах, а также типы торгов и транзакции, предоставленные обменом. Следовательно, одна и та же валюта может вызвать цены различий в различных транзакциях.",
    "bz12": "4. Инвестиционная прибыль?",
    "bz13": "Чем выше цена, тем выше, тем выше прибыль. В то же время Amazon Review Robot случайным образом распределяет крупные заказы комиссии.",
    "bz14": "Каждый день система автоматически генерирует и распространяет 60 товарных заказов пользователю. Крупные комиссионные заказы распределены.",
    "bz15": "USDT может быть снят после 60 заказов. (Если 60 заказов не будут выполнены, система автоматически остановится до момента избыточного заказа дня)",
    "bz16": "Каждая учетная запись может быть связана только с учетной записью USDT.",
    "bz17": "После завершения ежедневной доставки, вы можете нормально снять.",
    "bz18": "Перед перезарядкой, пожалуйста, связывайте свой адрес снятия (поддержка TRC-20) USDT Адрес.",
    "ty1": "Конечно",
    "ty2": "Отмена",
    "ty3": "представлять на рассмотрение",
    "ty4": "адрес",
    "s1": "Совокупный доход",
    "s2": "Служить",
    "s3": "о нас",
    "s4": "помощь",
    "s5": "Партнер",
    "s6": "Мой сервис",
    "d1": "отменен",
    "d2": "завершенный",
    "d3": "Нет данных",
    "d4": "Одиночный номер",
    "d5": "час транзакции",
    "d6": "Количество",
    "d7": "Текущий номер задачи",
    "d8": "Ожидаемое возвращение",
    "d9": "Все еще",
    "d10": "счет",
    "j1": "Баланс",
    "j2": "Введение транзакции",
    "j3": "Amazon Review Robot использует Amazon Review Robot отзывы каждый день для отображения продуктов, которые необходимо улучшить с помощью продаж Robot Amazon Review. Если пользователю нужно только щелкнуть по заказу, система автоматически генерирует подпись заказа. Пользователь платит сумму заказа через блокчейн USDT и может каждый раз получать более 0,6%комиссии, а система случайным образом распределяет большие заказы вознаграждения.",
    "j4": "Начните сопоставить",
    "j5": "Сегодняшние преимущества",
    "j6": "Заполнен номер",
    "j7": "Общая задача",
    "td1": "Баланс",
    "td2": "комиссия",
    "td3": "получать",
    "td4": "Всего людей",
    "td5": "пользователь",
    "td6": "способствовать",
    "td7": "количество",
    "td8": "состояние",
    "g1": "отзывать",
    "g2": "пополнить",
    "g3": "пригласить друзей",
    "g4": "Пригласить сейчас",
    "g5": "За каждую прибыль, полученную друзьями, вы получите соответствующую комиссию по долю",
    "g6": "персональная информация",
    "g7": "Детали капитала",
    "g8": "Зарядка записи",
    "g9": "Запись о снятии",
    "g10": "язык",
    "g11": "выход",
    "g12": "Вы уверены, что выходите и входите в систему?",
    "yh1": "Информация пользователя",
    "yh2": "Исправлять",
    "yh3": "настраивать",
    "yh4": "телефон",
    "yh6": "Старый пароль",
    "yh7": "Новый пароль",
    "mx1": "Компитация коллекции",
    "mx2": "доход",
    "mx3": "Отзывать",
    "mx4": "время",
    "mx5": "Перезарядка",
    "mx6": "отклоненный",
    "mx7": "В ожидании",
    "mx8": "Адрес отмены",
    "mx9": "доступные средства",
    "mx10": "На самом деле",
    "mx11": "Пожалуйста, введите сумму отмены",
    "cz1": "Депозитный адрес поддерживает только ERC20, минимальная сумма депозита составляет 20 USDT",
    "cz2": "копия",
    "cz3": "Выбор юридической валюты",
    "cz4": "Платить",
    "cz5": "Депозитный адрес поддерживает только TRC20-USDT, а минимальная сумма депозита составляет 20 USDT",
    "cz6": "Депозитный адрес поддерживает только ERC20, минимальная сумма депозита составляет 20 USDT",
    "qy1": "Регион код",
    "yq1": "пригласить друзей",
    "yq2": "Код приглашения",
    "yq3": "Пригласить друзей получить большой доход",
    "yq4": "Ну давай же"
  }
  
