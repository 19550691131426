import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import './help.css'
import _ from 'lodash'
import { getInfo, updateUserInfo } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Help() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='helpPage'>
            <div className='header'>
                <div className='backIcon' onClick={() => { navigate('/') }}>
                    <LeftOutline />
                </div>
                <p>{t('bz1')}</p>
            </div>
            <div className='help_box'>
                <Collapse accordion style={{ borderRadius: '8px', overflow: 'hidden' }}>
                    <Collapse.Panel key='1' title={t('bz2')}>
                        <div className='help_item'>
                            <h3>{t('bz6')}</h3>
                            <p>{t('bz7')}</p>
                        </div>
                        <div className='help_item'>
                            <h3>{t('bz8')}</h3>
                            <p>{t('bz9')}</p>
                        </div>
                        <div className='help_item'>
                            <h3>{t('bz10')}</h3>
                            <p>{t('bz11')}</p>
                        </div>
                        <div className='help_item'>
                            <h3>{t('bz12')}</h3>
                            <p>{t('bz13')}</p>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel key='2' title={t('bz3')}>
                        <div className='help_item'>
                            <p>{t('bz14')}</p>
                        </div>
                        <div className='help_item'>
                            <p>{t('bz15')}</p>
                        </div>
                        <div className='help_item'>
                            <p>{t('bz16')}</p>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel key='3' title={t('bz4')}>
                        <div className='help_item'>
                            <p>{t('bz17')}</p>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel key='4' title={t('bz5')}>
                        <div className='help_item'>
                            <p>{t('bz18')}</p>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    )
}
